/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const createChimeMeeting = /* GraphQL */ `query CreateChimeMeeting($title: String, $name: String, $region: String) {
  createChimeMeeting(title: $title, name: $name, region: $region) {
    statusCode
    headers
    body
    isBase64Encoded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CreateChimeMeetingQueryVariables,
  APITypes.CreateChimeMeetingQuery
>;
export const joinChimeMeeting = /* GraphQL */ `query JoinChimeMeeting($meetingId: String, $name: String) {
  joinChimeMeeting(meetingId: $meetingId, name: $name) {
    statusCode
    headers
    body
    isBase64Encoded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.JoinChimeMeetingQueryVariables,
  APITypes.JoinChimeMeetingQuery
>;
export const endChimeMeeting = /* GraphQL */ `query EndChimeMeeting($meetingId: String) {
  endChimeMeeting(meetingId: $meetingId) {
    statusCode
    headers
    body
    isBase64Encoded
    __typename
  }
}
` as GeneratedQuery<
  APITypes.EndChimeMeetingQueryVariables,
  APITypes.EndChimeMeetingQuery
>;
export const getMeeting = /* GraphQL */ `query GetMeeting($title: String!) {
  getMeeting(title: $title) {
    meetingId
    title
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingQueryVariables,
  APITypes.GetMeetingQuery
>;
export const listMeetings = /* GraphQL */ `query ListMeetings(
  $title: String
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMeetings(
    title: $title
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      meetingId
      title
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingsQueryVariables,
  APITypes.ListMeetingsQuery
>;
export const getAttendee = /* GraphQL */ `query GetAttendee($attendeeId: String!) {
  getAttendee(attendeeId: $attendeeId) {
    attendeeId
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttendeeQueryVariables,
  APITypes.GetAttendeeQuery
>;
export const listAttendees = /* GraphQL */ `query ListAttendees(
  $attendeeId: String
  $filter: ModelAttendeeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAttendees(
    attendeeId: $attendeeId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      attendeeId
      name
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttendeesQueryVariables,
  APITypes.ListAttendeesQuery
>;
export const meetingsByMeetingId = /* GraphQL */ `query MeetingsByMeetingId(
  $meetingId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  meetingsByMeetingId(
    meetingId: $meetingId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      meetingId
      title
      data
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MeetingsByMeetingIdQueryVariables,
  APITypes.MeetingsByMeetingIdQuery
>;
