import { useEffect, useState } from "react";

import {
  AudioInputControl,
  AudioOutputControl,
  ControlBar,
  ControlBarButton,
  Phone,
  useMeetingManager,
  MeetingStatus,
  useMeetingStatus,
  VideoInputControl,
  VideoTileGrid,
  useAudioVideo,
} from "amazon-chime-sdk-component-library-react";
import { TranscriptEvent } from "amazon-chime-sdk-js";
import { endMeeting } from "../utils/api";
import { toast } from "react-toastify";
import { isTranscript } from "../utils";
import LoadingOverlay from "react-loading-overlay-ts";

function Meeting() {
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const audioVideo = useAudioVideo();
  const [transcription, setTranscription] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (transcription === null) {
      return
    }

    const timeout = setTimeout(() => {
      setTranscription(null)
    }, 3000)

    return () => {
      clearTimeout(timeout)
    }
  }, [transcription])

  useEffect(() => {
    const transcriptEventHandler = (transcriptEvent: TranscriptEvent) => {
      if (!isTranscript(transcriptEvent)) {
        return;
      }

      if (!transcriptEvent.results.length) {
        return;
      }

      setTranscription(transcriptEvent.results[0].alternatives[0].transcript);
    };

    if (audioVideo) {
      audioVideo.transcriptionController?.subscribeToTranscriptEvent(
        transcriptEventHandler
      );
    }

    return () => {
      audioVideo?.transcriptionController?.unsubscribeFromTranscriptEvent(
        transcriptEventHandler
      );
    };
  }, [audioVideo, setTranscription]);

  const clickedEndMeeting = async () => {
    setIsLoading(true);
    const meetingId = meetingManager.meetingId;
    if (meetingId) {
      await endMeeting(meetingId);
      await meetingManager.leave();

      toast.success("Meeting has been ended");
    }

    setIsLoading(false);
  };

  return (
    <div
      style={{
        marginTop: "2rem",
        height: "40rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingOverlay
        active={isLoading}
        spinner
        styles={{
          overlay: (base) => ({
            ...base,
            position: "fixed",
          }),
        }}
      />
      {transcription && (
        <div
          style={{
            zIndex: 1,
            position: "absolute",
            width: "fit-content",
            minHeight: "25px",
            borderRadius: "10px",
            backgroundColor: "black",
            opacity: "0.7",
            fontWeight: "bold",
            fontSize: "15px",
            color: "white",
            padding: "5px 20px",
            lineHeight: "25px",
            fontFamily: "sans-serif",
            top: "500px",
          }}
        >
          {transcription}
        </div>
      )}
      {meetingStatus === MeetingStatus.Succeeded ? (
        <>
          <VideoTileGrid />
          <ControlBar layout="undocked-horizontal" showLabels>
            <AudioInputControl />
            <VideoInputControl />
            <AudioOutputControl />
            <ControlBarButton
              icon={<Phone />}
              onClick={clickedEndMeeting}
              label="End"
            />
          </ControlBar>
        </>
      ) : (
        <div />
      )}
    </div>
  );
}

export default Meeting;
