/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createMeetingGraphQL = /* GraphQL */ `mutation CreateMeetingGraphQL(
  $input: CreateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  createMeetingGraphQL(input: $input, condition: $condition) {
    meetingId
    title
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingGraphQLMutationVariables,
  APITypes.CreateMeetingGraphQLMutation
>;
export const deleteMeetingGraphQL = /* GraphQL */ `mutation DeleteMeetingGraphQL(
  $input: DeleteMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  deleteMeetingGraphQL(input: $input, condition: $condition) {
    meetingId
    title
    data
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingGraphQLMutationVariables,
  APITypes.DeleteMeetingGraphQLMutation
>;
export const createAttendeeGraphQL = /* GraphQL */ `mutation CreateAttendeeGraphQL(
  $input: CreateAttendeeInput!
  $condition: ModelAttendeeConditionInput
) {
  createAttendeeGraphQL(input: $input, condition: $condition) {
    attendeeId
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttendeeGraphQLMutationVariables,
  APITypes.CreateAttendeeGraphQLMutation
>;
export const deleteAttendeeGraphQL = /* GraphQL */ `mutation DeleteAttendeeGraphQL(
  $input: DeleteAttendeeInput!
  $condition: ModelAttendeeConditionInput
) {
  deleteAttendeeGraphQL(input: $input, condition: $condition) {
    attendeeId
    name
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttendeeGraphQLMutationVariables,
  APITypes.DeleteAttendeeGraphQLMutation
>;
