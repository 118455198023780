import React, { ChangeEvent, FC, FormEvent, useState } from 'react';

import {
  Flex,
  FormField,
  Input,
  PrimaryButton,
  useMeetingManager,
  useMeetingStatus,
  MeetingStatus,
} from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import { addAttendeeToDB, addMeetingToDB, createMeeting, getAttendeeFromDB, getMeetingFromDB, joinMeeting } from '../utils/api'
import { toast } from 'react-toastify'
import LoadingOverlay from 'react-loading-overlay-ts';

const MeetingForm: FC = () => {
  const meetingManager = useMeetingManager();
  const meetingStatus = useMeetingStatus();
  const [meetingTitle, setMeetingTitle] = useState('');
  const [attendeeName, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  function getAttendeeCallback() {
    return async (chimeAttendeeId: string, externalUserId?: string) => {
      const attendeeInfo: any = await getAttendeeFromDB(chimeAttendeeId);
      const attendeeData = attendeeInfo.data.getAttendee;
      return {
        name: attendeeData.name
      };
    }
  }

  const clickedJoinMeeting = async (event: FormEvent) => {
    setIsLoading(true);
    event.preventDefault();
  
    meetingManager.getAttendee = getAttendeeCallback();
    const title = meetingTitle.trim().toLocaleLowerCase();
    const name = attendeeName.trim();
  
  // Fetch the Meeting via AWS AppSync - if it exists, then the meeting has already
  // been created, and you just need to join it - you don't need to create a new meeting
    const meetingResponse: any = await getMeetingFromDB(title);
    const meetingJson = meetingResponse.data.getMeeting;
    try {
      if (meetingJson) {
        const meetingData = JSON.parse(meetingJson.data);
        const joinInfo = await joinMeeting(meetingData.MeetingId, name);
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);
  
        const sessionConfiguration = new MeetingSessionConfiguration(meetingData, joinInfo.Attendee);
        await meetingManager.join(sessionConfiguration);
      } else {
        const joinInfo = await createMeeting(title, name, 'ap-southeast-1');
        await addMeetingToDB(title, joinInfo.Meeting.MeetingId, JSON.stringify(joinInfo.Meeting));       
        await addAttendeeToDB(joinInfo.Attendee.AttendeeId, name);
  
        const sessionConfiguration = new MeetingSessionConfiguration(joinInfo.Meeting, joinInfo.Attendee);
        await meetingManager.join(sessionConfiguration);
      }

      // At this point you can let users setup their devices, or start the session immediately
      await meetingManager.start();
      toast.success(`You have been connected to Meeting ID: ${title}`)
    } catch (error: any) {
      console.log(error);
      toast.error(`An error occurred: ${error.message}`)
    }
    
    setIsLoading(false);
  };

  if (meetingStatus === MeetingStatus.Succeeded) {
    return null
  }

  return (
    <>
      <LoadingOverlay 
        active={isLoading} 
        spinner 
        styles={{
            overlay: (base) => ({
            ...base,
            position: 'fixed'
            })
        }}/>
      <form>
        <FormField
          field={Input}     
          label='Meeting Id'
          value={meetingTitle}
          fieldProps={{
            name: 'Meeting Id',
            placeholder: 'Enter a Meeting ID',
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setMeetingTitle(e.target.value);
          }}
        />
        <FormField
          field={Input}
          label="Name"
          value={attendeeName}
          fieldProps={{
            name: 'Name',
            placeholder: 'Enter your Attendee Name'
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value);
          }}
        />
        <Flex
          container
          layout="fill-space-centered"
          style={{ marginTop: '2.5rem' }}
        >
            <PrimaryButton label="Join Meeting" onClick={clickedJoinMeeting} />
        </Flex>
      </form>
    </>
  );
};

export default MeetingForm;