// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { generateClient } from 'aws-amplify/api';
import { createAttendeeGraphQL, createMeetingGraphQL, deleteMeetingGraphQL } from '../graphql/mutations';
import { createChimeMeeting, getAttendee, endChimeMeeting, getMeeting, joinChimeMeeting, meetingsByMeetingId } from '../graphql/queries';
import { MeetingFeatures } from 'amazon-chime-sdk-js';

const client = generateClient();

export async function createMeeting(title: string, attendeeName: string, region: string) {
  const joinInfo: any = await client.graphql({
    query: createChimeMeeting,
    variables: {title: title, name: attendeeName, region: region }
  });
  const joinInfoJson = joinInfo.data.createChimeMeeting;
  const joinInfoJsonParse = JSON.parse(joinInfoJson.body);
  return joinInfoJsonParse;
}

export async function joinMeeting(meetingId: string, name: string) {
  const joinInfo: any = await client.graphql({
    query: joinChimeMeeting,
    variables: {meetingId: meetingId, name: name},
  });
  const joinInfoJson = joinInfo.data.joinChimeMeeting;
  const joinInfoJsonParse = JSON.parse(joinInfoJson.body);
  return joinInfoJsonParse;
}

export async function endMeeting(meetingId: string) {
  const endInfo: any = await client.graphql({
    query: endChimeMeeting,
    variables: {meetingId},
  });
  const endInfoJson = endInfo.data.endChimeMeeting;

  const meetingData: any = await client.graphql({
    query: meetingsByMeetingId,
    variables: {meetingId}
  })
  if (meetingData.data.meetingsByMeetingId) {
    await client.graphql({
      query: deleteMeetingGraphQL,
      variables: {input: { title: meetingData.data.meetingsByMeetingId.items[0].title }}
    });
  }
  return endInfoJson;
}

export async function addMeetingToDB(title: string, meetingId: string, meetingData: string) {
  await client.graphql({
    query: createMeetingGraphQL,
    variables: {input: {title: title, meetingId: meetingId, data: meetingData, }}
  });
}

export async function addAttendeeToDB(attendeeID: string, attendeeName: string) {
  await client.graphql({
    query: createAttendeeGraphQL,
    variables: {input: {attendeeId: attendeeID, name: attendeeName }}
  });
}

export async function getMeetingFromDB(title: string) {
  const meetingInfo = await client.graphql({
    query: getMeeting,
    variables: { title }
  });
  return meetingInfo;
}

export async function getAttendeeFromDB(attendeeId: string) {
  const attendeeInfo = await client.graphql({
    query: getAttendee,
    variables: {attendeeId: attendeeId }
  });
  return attendeeInfo;
}