/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://qbko6yobnng6bies24d3a6ydo4.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-l7gcdmbgjzdmdadym3a47nk6ly",
    "aws_content_delivery_bucket": "ewmplify-20240705010650-hostingbucket-stg",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d1abw8g8r9lo9.cloudfront.net"
};


export default awsmobile;
